import { background } from "../../assets";

import Slider from "react-slick";
import { experienceCards } from "../../repository/cards.repository";

import { BaseSyntheticEvent, useEffect, useState } from "react";
import { CardItem } from "../../components/CardImage";
import { saveInvitee } from "../../firebase";
import {
  CardContainer,
  CarrouselContainer,
  ConfirmYourPresence,
  FormButton,
  Forms,
  FormsContainer,
  HomeContainer,
  SeeAllExperiencesLink,
  Subtitle,
  SubtitleContainer,
  Title,
  TitleContainer,
} from "./styles";
import FormInput from "./FormInput";

export const Home = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1.1,
        },
      },
    ],
  };

  const [invinteeName, setInviteeName] = useState("");
  const [invinteeNumber, setInviteeNumber] = useState("");

  const handleChangeName = (event: BaseSyntheticEvent) => {
    setInviteeName(event.target.value);
  };

  const handleChangeNumber = (event: BaseSyntheticEvent) => {
    setInviteeNumber(event.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await saveInvitee({ name: invinteeName, number: invinteeNumber });
      alert(`Obrigada por confirmar sua presença, ${invinteeName}`);
    } catch (e) {
      alert("Algo deu errado, tente mais tarde!");
    }
  };

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  return (
    <HomeContainer image={background}>
      <TitleContainer>
        <Title>Casamento de </Title>
        <Title>Camila e Isabelly</Title>
      </TitleContainer>

      <SubtitleContainer>
        <Subtitle>Experiências</Subtitle>
      </SubtitleContainer>

      <CardContainer>
        <CarrouselContainer>
          <Slider {...settings}>
            {experienceCards.map((element) => (
              <CardItem
                key={element.id}
                id={element.id}
                url={element.imageUrl}
                text={element.name}
                description={element.description}
                amount={element.price}
              />
            ))}
          </Slider>

          <SeeAllExperiencesLink to="/all-experiences">
            Ver todas
          </SeeAllExperiencesLink>
        </CarrouselContainer>
      </CardContainer>

      <ConfirmYourPresence>
        <Subtitle>Confirme a sua presença</Subtitle>
        <FormsContainer>
          <Forms onSubmit={handleSubmit}>
            <FormInput
              type="text"
              name="name"
              placeholder="Isabelly Damascena"
              onChange={handleChangeName}
              title="Digite seu nome"
            />

            <FormInput
              type="telefone"
              name="phone"
              placeholder="(81) XXXXX-XXXX"
              onChange={handleChangeNumber}
              title="Digite seu número de celular"
            />
            <FormButton>Enviar</FormButton>
          </Forms>
        </FormsContainer>
      </ConfirmYourPresence>

      {/* <DateContainer>
       
                <DateImage src={date} alt="date"/> 
            </DateContainer> */}
    </HomeContainer>
  );
};
