import styled from "styled-components";
import { device } from "../../devices";

type AllExperiencesContainerProps = {
  image?: string;
};

export const AllExperiencesContainer = styled.div<AllExperiencesContainerProps>`
  width: 100vw;
  padding: 64px 10rem;

  background-image: url(${(props) => props.image});
  background-size: 100% cover;

  @media ${device.mobileL} {
    padding: 40px 2rem;
  }
`;

export const Subtitle = styled.p`
  font-family: 'Quicksand';
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 28px;

  color: black;

  @media ${device.mobileL} {
    font-size: 1.8rem;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 24px;

  justify-content: left;

  margin-bottom: 24px;

  @media ${device.mobileL} {
    justify-content: center;
  }
`;
