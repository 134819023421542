// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { Firestore, doc, getFirestore, setDoc } from 'firebase/firestore/lite'; // TODO: Add SDKs for Firebase products that you want to use
import uuid from "react-uuid";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBu5xHNtuVKSM_sqPrvz4R1e_BH3yVgRCQ",
  authDomain: "db-casamento.firebaseapp.com",
  projectId: "db-casamento",
  storageBucket: "db-casamento.appspot.com",
  messagingSenderId: "9948840668",
  appId: "1:9948840668:web:2ebaef21c469cdf7bcea19",
  measurementId: "G-DRBRFZ7KF3"
};

// Initialize Firebase

let app: FirebaseApp;
let db: Firestore;

export const setUpFireBase = () => {
    app = initializeApp(firebaseConfig);
    db = getFirestore(app);
}
// const analytics = getAnalytics(app);

type Invitee = {
    name: string,
    number: string,
}

export const saveInvitee = async (invitee: Invitee) =>  {
    const testRef = doc(db, 'invitees', uuid())
    await setDoc(testRef, {...invitee})
}