import styled from 'styled-components';


type HomeContainerProps = {
  image?: string;
}

export const HomeContainer = styled.div<HomeContainerProps> `
  width: 100vw;
  // height: 100vh;

  background-image: url(${(props) => props.image});
  background-size: 100% cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 16px;

  padding-bottom: 24px;

`;

export const QrCodeImage = styled.img`
    width: 25%;
`;

export const QrCodeContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 4rem;


`;

export const PixContainer = styled.div`
  width: 100%;
  
  padding-top: 2.5rem;

  display: flex;
  align-items: center;
  justify-content: center;


`;

export const Title = styled.p`
  font-family: 'Quicksand';
  font-size: 32px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.text};
  
`;

export const PixValueContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  gap: 16px;

`;

export const PixInput = styled.input`
  font-family: 'Muli';
  border: none;
  outline: none;

  padding: 8px 16px 8px 6px;

  width: 40%;
`;

export const PixButton = styled.button`
  font-family: 'Muli';
  border: none;
  outline: none;
  color: white;
  padding: 8px 24px;
  border-radius: 4px;

  background-color: ${(props) => props.theme.colors.purple};

`;

export const Description = styled.p`
  font-family: 'Muli';
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.text};
  margin-top: 0.8rem;

  padding-left: 64px;
  padding-right: 64px;
`;