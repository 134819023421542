import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  margin-top: auto;
  margin-left: auto;
  margin-right: 12px;
  padding-bottom: 8px;

  text-decoration: none;
`;

type LinkTextProps = {
  size?: "small" | "medium" | "large";
};

export const LinkText = styled.p<LinkTextProps>`
  text-align: right;
  color: white;

  padding: ${(props) =>
    props.size === "small"
      ? "4px 8px"
      : props.size === "medium"
      ? "8px 16px"
      : "10px 24px"}};

  font-family: 'Muli';
  font-size: ${(props) =>
    props.size === "small"
      ? "12px"
      : props.size === "medium"
      ? "12px"
      : "14px"}};

  border-radius: 4px;

  background-color: ${(props) => props.theme.colors.purple};

  width: fit-content;
`;
