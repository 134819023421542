import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../../devices';


type HomeContainerProps = {
  image?: string;
}

export const Title = styled.p`
  font-family: 'Mali';
  font-size: 4rem;
  font-weight: bold;
  text-align: left;

  @media ${device.mobileL} {
    font-size: 2.5rem;
  }
 
`;

export const Subtitle = styled.p`
  font-family: 'Quicksand';
  font-size: 2.4rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 16px;

  color: black;

  @media ${device.mobileL} {
    font-size: 1.8rem;
  }
`;

export const SubtitleContainer = styled.div`
  width: 100%;
  padding: 0 10rem;

  @media ${device.mobileL} {
    padding: 85px 2rem 0 2rem;
  }

`;
export const HomeContainer = styled.div<HomeContainerProps> `
  width: 100%;
  height: 2210px;

  background-image: url(${(props) => props.image});
  background-size: 100% cover;

  display: flex;
  flex-direction: column;

  @media ${device.mobileL} {
    height: 1100px;
  }

`;

export const ConfirmYourPresence = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 15%;

  padding: 2.5rem 10rem;

  @media ${device.mobileL} {
    padding: 2.5rem 2rem;
  }
`;

export const SeeAllExperiencesLink = styled(Link)`
  font-family: 'Muli';
  text-decoration: none;

  display: flex;
  margin-top: 8px;

  color: rgba(0, 0, 0, 0.52);

  margin-left: 3rem;
  margin-top: 24px;

  @media ${device.mobileL} {
    margin-top: 10px;
    margin-left: 2rem;
  }
`;


export const CardContainer = styled.div`
  width: 100%;

  background-color: rgba(162, 161, 132, 0.7);

  padding: 24px 7rem;

  display: flex;
  align-items: center;
  justify-content: flex-start !important;

  @media ${device.mobileL} {
    padding-left: 0rem;
    padding-right: 0rem;
    height: 30%;
  }


`;

export const TitleContainer = styled.div`
  width: 100%;
  height: 15%;

  padding-top: 8rem;
  padding-left: 10rem;

  color: black;

  @media ${device.mobileL} {
    padding-left: 2rem;
    padding-top: 5rem;
    height: 10%;
  }

`;

export const CarrouselContainer = styled.div`
  width: 100%;
`;

export const FormsContainer = styled.div`
  width: 500px;
  background-color: ${(props) => props.theme.colors.secondary24};

  border-radius: 12px;

  margin-top: 1rem;

  @media ${device.mobileL} {
    width: 100%;
  }

`;

export const Forms = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 48px 48px 24px 48px;

  @media ${device.mobileL} {
    padding: 32px 32px 12px 32px;
  }

`;

export const FormInputInput = styled.input`

  font-family: 'Muli';
  border: none;
  outline: none;

  font-size: 14px;

  border-bottom: 1px solid gray;
  background-color: transparent;

  width: 100%;
`;

export const FormInputLabel = styled.div`
  font-family: 'Muli';
  font-size: 13px;
  margin-bottom: 4px;
`

export const FormButton = styled.button`
  font-family: 'Muli';
  align-self: flex-end;
  margin-top: 12px;

  border: none;
  outline: none;

  border-radius: 4px;

  background-color: ${(props) => props.theme.colors.purple};
  color: ${(props) => props.theme.colors.white};

  padding: 8px 32px;

  @media ${device.mobileL} {
    margin-bottom: 16px;
  }

`;

export const DateContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 20rem;

  @media ${device.mobileL} {
    display: none; 
  }

`;

export const DateImage = styled.img`
  width: 50%;
`;