import React from "react";

import { LinkText, StyledLink } from "./style";
import { LinkProps } from "react-router-dom";

type ButtonProps = {
  link?: boolean;
  label?: string;
  to?: LinkProps["to"];
  onClick?: LinkProps["onClick"];
  size?: "small" | "medium" | "large";
};

export const Button: React.FC<ButtonProps> = ({
  link,
  label,
  to,
  onClick,
  size = "medium",
  ...props
}) => {
  if (link) {
    if (!to) throw Error("You must provide a 'to' prop when using 'link'");

    return (
      <StyledLink to={to} onClick={onClick}>
        <LinkText size={size}>{label}</LinkText>
      </StyledLink>
    );
  }

  return <div>{label}</div>;
};
