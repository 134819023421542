export const theme = {
  colors: {
    whiteCard: "#FEFEFE",
    whiteBackground: "#F4F4F4",
    white: "#D2D2D2",
    text: "#424141",
    primary: "#292929",
    background: "#e1ebe0",
    green: "#68652C",
    orange: "#DC855C",
    lightGreen: "#A2A184",
    darkGreen: "#3A3924",
    darkOrange: "#8D4D2A",
    purple: "#9C81C8",
    secondary: "rgba(162, 161, 132, 0.5)",
    secondary24: "rgba(162, 161, 132, 0.24)",
  },
};
