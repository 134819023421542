import React from "react";

import {
  CardContainer,
  CardDescription,
  CardImage,
  CardImageContainer,
  CardText,
  CardPrice,
  CardCTAContainer,
  TextButton,
} from "./style";

interface CardItemProps {
  id: string;
  text: string;
  description: string;
  amount: string;
  url: string;
}

export const CardItem: React.FC<CardItemProps> = (props: CardItemProps) => {
  const url = props.url
    ? props.url
    : "https://media.gazetadopovo.com.br/2022/03/25191630/sociedade_da_mesa_vinhos_clube_de_vinhos_revista_digital_brunello_di_montalcino_o_vinho_por_tras_da_fama-disclaimer-16x9-960x540.jpg";

  const handleClick = () => {
    localStorage.setItem("selectedItem", props.id);
  };

  return (
    <CardContainer to={"/experience-details"} onClick={handleClick}>
      <CardImageContainer>
        <CardImage src={url} alt={props.text} />
      </CardImageContainer>
      <CardText>{props.text}</CardText>
      <CardDescription>{props.description}</CardDescription>
      <CardPrice> R${props.amount}</CardPrice>
      <CardCTAContainer to={"/experience-details"} onClick={handleClick}>
        <TextButton>Dar presente</TextButton>
      </CardCTAContainer>
    </CardContainer>
  );
};
