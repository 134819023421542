import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../devices";


export const Container = styled.div`

    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;

    background-color: ${(props) => props.theme.colors.white};
`;

export const SliderContainer = styled.div`
    width: 100%;
    height: 500px;

    padding: 0% 10%;

    position: relative;
    bottom: 60px;
`;

export const TitleContainer = styled.div`

    width: 100%;
    height: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    /* background-color: ${(props) => props.theme.colors.darkOrange}; */

    background-image: url("https://res.cloudinary.com/citi-pta-techcenter/image/upload/v1686801020/background_zgshvg.png");
    background-size: cover;
    background-position: center;

    padding: 5% 10%;
`;

export const ButtonWedding = styled.button`
    padding: 15px 0px;

    background-color: ${(props) => props.theme.colors.orange};
    border: none;
    border-radius: 4px;
`;


export const Title = styled.p`

    font-size: 64px;
    color: ${(props) => props.theme.colors.text};
`;

export const SubtitleContainer = styled.div`
    display: flex;

    width: 40%;
    
    flex-direction: column;
    justify-content: flex-start;
    
    gap: 48px;
`;

export const Subtitle = styled.p`
    font-size: 17px;
    font-weight: 300;
    color: ${(props) => props.theme.colors.text};
`;

export const Image = styled.img`

    width: 320px;
    height: 320px;
`;

export const CardContainer = styled(Link)`
    width: 14.6rem;
    height: 300px;

    background: ${(props) => props.theme.colors.whiteCard};
    display: flex;
    flex-direction: column;

    border-radius: 4px;

    text-decoration: none;

    @media ${device.mobileL} {
        height: 270px;
    }

`;

export const CardDescription = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: gray;

    padding: 8px 12px;

    text-align: left;

    height: fit-content; 

    max-height: 120px;
    
`;

export const CardPrice = styled.div`
    font-family: 'Muli';
    margin: 0 12px;

    // color: black;
    // font-weight: 400;

    // width: fit-content;
    // padding: 2px 4px;
    // border-radius: 4px;
    // background-color: #ffeaea;

    color: black;
    font-weight: 400;
    font-size: 16px;

`;

export const CardImageContainer = styled.div`
    height: 45%;
    padding-bottom: 10px;
    
`;

export const CardImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px 4px 0 0;

`;

export const CardText = styled.p`
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 600;
    color: black;
    margin: 0 12px;

    // background-color: salmon;
    // padding: 4px 12px;
    // // border-radius: 4px;
`;

export const CardCTAContainer = styled(Link)`
    margin-top: auto;
    margin-left: auto;
    margin-right: 12px;
    padding-bottom: 8px;

    text-decoration: none;
`

export const TextButton = styled.p`
    font-family: 'Quicksand';
    text-align: right;
    font-size: 12px;
    color: white;

    padding: 4px 8px;
    border-radius: 4px;
  
    background-color: ${(props) => props.theme.colors.purple};

    width: fit-content;
`;



