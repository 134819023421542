import { Route, Routes } from 'react-router-dom';

import { ExperienceDetailsScreen, Home, AllExperiencesScreen } from '../pages';


export const PublicRoutes = (): JSX.Element => (
    <Routes>
        <Route path='/' element = { <Home/> }/>
        
        <Route path='/experience-details' element = { <ExperienceDetailsScreen />} />

        <Route path='/all-experiences' element = { <AllExperiencesScreen />} />
        
    </Routes>
);