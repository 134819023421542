
interface ExpericenceCards { 
    id: string,
    name: string, 
    imageUrl: string, 
    price: string,
    description: string
}

export const experienceCards: Array<ExpericenceCards> = [
    {
        id: 'mensalidade-gympass',
        name: "Mensalidade do Gympass da Noiva",
        imageUrl: "https://images.pexels.com/photos/703012/pexels-photo-703012.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "300,00",
        description: "Para as noivas continuarem no projeto Gracyanne Barbosa."
    },
    {
        id: 'cabelo-colorido',
        name: "2 Tratamentos Capilares",
        imageUrl: "https://images.pexels.com/photos/7755511/pexels-photo-7755511.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "250,00",
        description: "Hidratação, nutrição e recuperação para o cabelo da noiva aguentar as colorações."
    },
    {
        id: 'corte-cabelo',
        name: "4 Cortes de Cabelo",
        imageUrl: "https://images.pexels.com/photos/3993460/pexels-photo-3993460.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "280,00",
        description: "Para a noiva ficar com o cabelinho na régua."
    },
    {
        id: 'internet',
        name: "1 mês de internet pago",
        imageUrl: "https://images.pexels.com/photos/1251837/pexels-photo-1251837.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "320,00",
        description: "Para as noivas conseguirem trabalhar e pagar o casamento."
    },
    {
        id: 'ovo-pago',
        name: "2 meses de ovo pago",
        imageUrl: "https://images.pexels.com/photos/1625385/pexels-photo-1625385.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "120,00",
        description: "Para garantir o shape da noiva durante o projeto Gracyanne Barbosa."
    },
    {
        id: 'mensalidade-netflix',
        name: "Mensalidade da Netflix",
        imageUrl: "https://images.pexels.com/photos/2726370/pexels-photo-2726370.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "55,90",
        description: "Para que as noivas continuem mantendo as séries em dia."
    },
    {
        id: 'ração-gatas',
        name: "Saco de 10kg de ração",
        imageUrl: "https://images.pexels.com/photos/3318215/pexels-photo-3318215.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "150,00",
        description: "Para garantir a alimentação das gatas não humanas da casa."
    },
    {
        id: 'rodada-pastel',
        name: "Rodada de pastel e cervejinha no Babilon",
        imageUrl: "https://images.pexels.com/photos/1269025/pexels-photo-1269025.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        price: "100,00",
        description: "Para animar as sexta feiras das noivinhas falidas pós casamento."
    },
    {
        id: 'cartao-shein',
        name: "Cartão presente SHEIN",
        imageUrl: "https://www.whistl.co.uk/515x430/5416/0431/2455/Shein_logo.PNG",
        price: "215,00",
        description: "Para os lookinhos das leoninas e decoração da casa."
    },
    {
        id: 'caipirinha-bananazinha',
        name: "Garrafa de Bananazinha",
        imageUrl: "https://70096.cdn.simplo7.net/static/70096/sku/166689410241150.jpg",
        price: "65,00",
        description: "Ajude as noivas a não entrarem nervosas na cerimônia."
    },
    {
        id: 'cafe-paris',
        name: "Café da manhã em Paris",
        imageUrl: "https://images.pexels.com/photos/2130616/pexels-photo-2130616.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "85,00",
        description: "Para que as noivas se alimentem bem em sua primeira viagem na europa."
    },
    {
        id: 'transporte-paris',
        name: "Passagem low-cost - Paris",
        imageUrl: "https://images.pexels.com/photos/2033343/pexels-photo-2033343.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "200,00",
        description: "Ajude as noivas a no transporte para que elas possam tomar o café da manhã em Paris."
    },
    {
        id: 'casa-limpa',
        name: "Casa limpa na sua proxima visita",
        imageUrl: "https://images.pexels.com/photos/4239037/pexels-photo-4239037.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "160,00",
        description: "Para que as noivas deixem o cafofinho arrumado na sua proxima visita."
    },
    {
        id: 'suplementos-noivas',
        name: "Suplementos das noivas",
        imageUrl: "https://images.pexels.com/photos/7615574/pexels-photo-7615574.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        price: "360,00",
        description: "Para para não faltar whey e creatina na casa das noivas."
    },
    {
        id: 'kit-tpm',
        name: "Kit TPM das Noivas",
        imageUrl: "https://images.pexels.com/photos/5938358/pexels-photo-5938358.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        price: "180,00",
        description: "Para garantir tpms mais tranquilas: Remédio, chocolate e cupons no ifood. Afinal são duas."
    },
    {
        id: 'dj-rave',
        name: "Mais 1h de DJ Mau",
        imageUrl: "https://images.pexels.com/photos/2717073/pexels-photo-2717073.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "110,00",
        description: "Para a festa não acabar... hoje eu não vou dormir, MICHAEL DOUGLAS!!!!"
    },
    {
        id: 'fotografia-suborno',
        name: "Suborno a fotografa para fugir das fotos de casamento",
        imageUrl: "https://images.pexels.com/photos/243757/pexels-photo-243757.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "222,50",
        description: "Para fugir das fotos quando a cachaça bater."
    },
    {
        id: 'viagem-sintra',
        name: "Viagem a Sintra",
        imageUrl: "https://images.pexels.com/photos/1547735/pexels-photo-1547735.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "458,25",
        description: "Para as noivas visitarem o set de gravação de Game of Thrones "
    },
    {
        id: 'noite-queijos',
        name: "Noite de Queijos e Drinks",
        imageUrl: "https://images.pexels.com/photos/7510803/pexels-photo-7510803.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "152,75",
        description: "Tour pelos restaurantes portugueses comenndo e bebendo."
    },
    {
        id: 'dis-ney',
        name: "Acesso a Disney - Paris",
        imageUrl: "https://images.pexels.com/photos/879844/pexels-photo-879844.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "580,75",
        description: "Contribua para o sonho de princesa das noivas de entrar no mundo de magias."
    },
    {
        id: 'dis-ney',
        name: "Chopp Tour em Barcelona",
        imageUrl: "https://images.pexels.com/photos/7016430/pexels-photo-7016430.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        price: "122,20",
        description: "Pague as cervejas das noivas em Barcelona."
    }
]


export const getExperienceById = (id: string) => experienceCards.find((element) => element.id === id)
