import { background } from '../../assets';
import { getExperienceById } from '../../repository/cards.repository';
import {
  Description,
  HomeContainer,
  PixButton,
  PixContainer,
  PixInput,
  PixValueContainer,
  QrCodeContainer, QrCodeImage,
  Title
} from "./styles";

import { qrcode } from "../../assets";

export const ExperienceDetailsScreen = () => {

    const itemId = localStorage.getItem('selectedItem')
    const choosenExperience = getExperienceById(itemId as string);

    const text = "00020101021126830014br.gov.bcb.pix01364ad54936-82cc-40cd-9e17-62504832f8c10221Presente de Casamento5204000053039865802BR5924Camila Maria De Oliveira6009SAO PAULO622905251H828H2PKZPF96WYNJ0PF6FT563044EB2"

    async function copyTextToClipboard(text: string) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } else {
          return document.execCommand('copy', true, text);
        }
      }
    

    const handleCopyClick = () => {
        copyTextToClipboard(text)
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }

    return (
        <HomeContainer image={background} >


            <QrCodeContainer>
                <QrCodeImage src={qrcode} alt="qrcode" />
            </QrCodeContainer>

            <PixContainer>
                <Title>Pix copia e cola</Title>
            </PixContainer>

            <PixValueContainer>
                <PixInput type="text" value={text} readOnly />
                <PixButton onClick={handleCopyClick}>Copiar</PixButton>
            </PixValueContainer>

            <Description>

                Use o QR code ou pix copia e cola acima para contribuir com {choosenExperience?.name}: R$ {choosenExperience?.price}. Caso opte fazer o pagamento em crédito, utilizar a função pix parcelado do seu banco. Se essa função não estiver disponível, no seu banco, sugerimos a utilização do Picpay. É necessário baixar o aplicativo e criar uma conta, em seguida, cadastrar o cartão de crédito que você seja usar. 
            </Description>
            <Description><i>Et voilá. Ç'fini! </i></Description>
            <Description>* Atenção, isso não é publi 🥲, fazemos uso desse serviço quando precisamos parcelar algo que tem juros no parcelamento e os juros do PicPay são os menores para esse tipo de transação *</Description>

        </HomeContainer>
    );
}