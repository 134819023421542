import { background } from "../../assets";
import {
  experienceCards,
  getExperienceById,
} from "../../repository/cards.repository";
import { AllExperiencesContainer, Subtitle, Grid } from "./styles";

import { qrcode } from "../../assets";
import { CardItem } from "../../components/CardImage";
import { Button } from "../../components";

export const AllExperiencesScreen = () => {
  return (
    <AllExperiencesContainer image={background}>
      <Subtitle>Experiências</Subtitle>

      <Grid>
        {experienceCards.map((element) => (
          <CardItem
            key={element.id}
            id={element.id}
            url={element.imageUrl}
            text={element.name}
            description={element.description}
            amount={element.price}
          />
        ))}
      </Grid>

      <Button link label="Voltar" to="/" size="large" />
    </AllExperiencesContainer>
  );
};
