import { HTMLProps } from "react";
import { FormInputInput, FormInputLabel } from "./styles";

type FormInputProps = HTMLProps<HTMLInputElement>;

const FormInput: React.FC<FormInputProps> = ({
  type,
  name,
  placeholder,
  onChange,
  title,
}) => {
  return (
    <div>
      <FormInputLabel>{title}</FormInputLabel>
      <FormInputInput {...{ type, name, placeholder, onChange }} />
    </div>
  );
};

export default FormInput;
